.tab-button {
    transition: border-color 0.3s ease, background-color 0.3s ease;
  }
  
  .tab-button.active {
    /* border-bottom-width: 2px; */
    /* border-bottom-color: #3b82f6; Tailwind blue-500 */
    background-color: rgba(59, 130, 246, 0.1); /* Light blue background */
  }
  
  .icon-wrapper {
    margin-right: 1rem;
    transition: transform 0.3s ease;
  }
  
  .icon-wrapper.icon-active {
    transform: scale(1.1);
  }
  
  .terminal {
    padding-top: 2.5rem;
    transition: opacity 0.3s ease;
  }
  
  .tab-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }
  
  .tab-button {
    padding: 1rem;
    cursor: pointer;
    position: relative;
    transition: color 0.3s, background-color 0.3s;
  }
  
  .tab-button span {
    margin-left: 0.5rem;
  }
  
  .tab-button.active {
    /* color: #3b82f6; Tailwind blue-500 */
  }
  
  .tab-indicator {
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #443ACC; /* Tailwind blue-500 */
    transition: all 0.3s ease;
  }
  

  @media (max-width: 640px) {
    .tab-button {
      flex-direction: row;
    }
  
    .icon-wrapper {
      margin-right: 8px; /* Restore margin-right on larger screens */
    }
  }
  
  